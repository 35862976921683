
import { defineComponent, ref } from 'vue'
import TmAlert from '@/components/shared/TmAlert.vue'
import TmButton from '@/components/shared/TmButton.vue'
import { notify } from '@kyvg/vue3-notification'

export default defineComponent({
  name: 'Alerts',
  components: { TmButton, TmAlert },
  setup() {
    const notificationText = 'Your BYOC 10K free trial plan has been successfully activated.'

    const alerts = ref([
      {
        class: 'warning',
        icon: 'warning',
        title: 'Warning',
      },
      {
        class: 'info',
        icon: 'info',
        title: 'Info',
      },
      {
        class: 'success',
        icon: 'check_circle',
        title: 'Success',
      },
      {
        class: 'error',
        icon: 'error',
        title: 'Error',
      },
    ])

    return {
      notificationText,
      notify,
      alerts,
    }
  },
})
